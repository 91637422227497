body::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);  /* Crna boja sa 70% prozirnosti */
    z-index: -1;  /* Stavlja sloj ispod ostatka sadržaja */
}

#root {
    position: relative;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.success-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    z-index: 10001;
    border-radius: 5px;
    text-align: center;
    font-size: 1.5em;
}

.buy-button {
    background-color: rgb(2, 167, 79);
    color: white; /* ovo je boja teksta na gumbu */
    /* ostale stilove možete dodati ovdje */
}

.buy-button:hover {
    background-color: rgb(0, 126, 81);
    /* ostale stilove možete dodati ovdje */
}
